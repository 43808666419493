/* swiper */

.swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
}
.swiper-slide {
    flex: 1 0 auto;
}

.swiper-nav {
    display: flex;
    pointer-events: none;
    justify-content: space-between;
}
.swiper-nav svg{
    fill: none;
}
.swiper-nav > * {
    pointer-events: auto;
    transition: .15s transform;
    transition: opacity .5s;
    height: 100%;
}
.swiper-nav > *.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
}
.swiper-nav > *:active {
    transform: scale(.9)
}